import { gql } from "@apollo/client";
import { EventMetrics, Installation, Metric } from "@technis/shared";
import { client, Mutation } from "./index";

export type AllInstallationsQueryResult = {
  allInstallations: Pick<Installation, "id" | "name">[];
};

export const ALL_INSTALLATIONS_QUERY = gql`
  query {
    allInstallations {
      id
      name
    }
  }
`;

export const INSTALLATION_CONNECTION_QUERY = gql`
  query installationConnection($searchValue: String) {
    installationConnection(first: 10, filter: { searchValue: $searchValue }) {
      count
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          organizationId
          address
          creationDate
        }
        cursor
      }
    }
  }
`;

export const CREATE_SUPPORT_DATA_REQUEST = gql`
  mutation sendSupportReport($eventId: Int!, $begin: Float!, $end: Float!, $email: String!, $xovisDiagnostics: Boolean!) {
    sendSupportReport(eventId: $eventId, begin: $begin, end: $end, email: $email, xovisDiagnostics: $xovisDiagnostics)
  }
`;

export const GET_INSTALLATION_INFO_QUERY = gql`
  query getInstallationInfo($installationId: Int!) {
    getInstallationInfo(installationId: $installationId) {
      id
      name
      address
      organizationId
      timezone
      metrics
      linkVision
      linksLegacy
      change_of_day_time
      opening_hours
      events {
        id
        name
        dateBegin
        dateEnd
        neverEnding
        metrics
      }
      users {
        id
        email
        name
      }
      devices {
        id
        name
        lastStatus
        lastConnectionDate
        passageId
        zoneId
      }
      cartographyDetails {
        zones {
          id
          name
          parentIds
          childIds
          passageIds
          countMinMax
        }
        passages {
          id
          name
          zoneInIds
          zoneOutIds
          padIds
        }
      }
    }
  }
`;

export type InstallationConnectionEdge = {
  node: {
    id: number;
    name: string;
    organizationId: number;
    address: string;
    creationDate: number;
  };
  cursor: string;
};

export type InstallationConnectionQueryResult = {
  installationConnection: {
    count: number;
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: InstallationConnectionEdge[];
  };
};

export type InstallationInfoQueryResult = {
  getInstallationInfo: {
    id: number;
    name: string;
    address: string;
    organizationName: string;
    organizationId: number;
    timezone: string;
    metrics: EventMetrics[];
    linksLegacy: string[];
    linkVision: string[];
    change_of_day_time: Installation["change_of_day_time"];
    opening_hours: Installation["opening_hours"];
    events: {
      id: number;
      name: string;
      dateBegin: number;
      dateEnd: number;
      metrics: Metric[];
      neverEnding: boolean;
    }[];
    users: {
      id: number;
      name: string;
      email: string;
    }[];
    devices: {
      id: number;
      name: string;
      lastStatus: string;
      lastConnectionDate: number;
      passageId: number;
      zoneId: number;
    }[];
    cartographyDetails: {
      zones: {
        id: number;
        name: string;
        parentIds: number[];
        childIds: number[];
        passageIds: number[];
        countMinMax: number[];
      }[];
      passages: {
        id: number;
        name: string;
        zoneInIds: number[];
        zoneOutIds: number[];
        padIds: number[];
      }[];
    };
  };
};

export type SupportDataInput = {
  eventId: number;
  begin: number;
  end: number;
  email: string;
  xovisDiagnostics: boolean;
};

export type CreateSupportDataRequestResult = Mutation<{ sendSupportReport: boolean }>;

export const InstallationService = {
  sendSupportData: (supportDataInput: SupportDataInput): Promise<CreateSupportDataRequestResult> =>
    client.mutate(CREATE_SUPPORT_DATA_REQUEST, {
      eventId: supportDataInput.eventId,
      begin: supportDataInput.begin,
      end: supportDataInput.end,
      email: supportDataInput.email,
      xovisDiagnostics: supportDataInput.xovisDiagnostics,
    }),
};
