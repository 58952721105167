import { gql } from "@apollo/client";
import { DashboardConfigurationJSON } from "@technis/shared";
import { Mutation, client } from ".";
import cleanDeep from "clean-deep";

const createDashboardMutation = gql`
  mutation createOrUpdateDashboard($config: WidgetConfigurationJSONInput) {
    createOrUpdateDashboardConfiguration(data: $config)
  }
`;

const getDashboardConfigurationQuery = gql`
  query ($id: Int!) {
    getDashboardConfiguration(dashboardId: $id) {
      dashboard {
        id
        name
        userEmail
        userEmails
        icon
        organizationId

        pages {
          id
          name
          icon
          widgets {
            id
            title
            kind
            type
            category
            metrics {
              type
              aggregation
              sortBy
              filters
              granularity
              groupBy {
                type
                tagId
                tagIds
                aggregation
                col
                granularity
              }
              limit
              customTitle
              customSubTitle
              target
              configuration
            }
            configuration {
              stacked
              isPercentage
              halfCircle
              showLegend
              showMinMaxLabel
              isParallelCoordinates
              installationId
              hideUnit
              useCustomFormatter
              isKpiMenuHidden
            }
            tooltip
            liveTooltip
            realTime
            ignoredFilters
            colorimetryConfig
            useCustomFormatter
            hideUnit
            isKpiMenuHidden
            zoneIds
            passageIds
            deviceIds
          }
          globalFilters {
            id
            categoryId
            type
            zoneIds
            dayFilters {
              operator
              values
              criterion
            }
            dateFilters {
              operator
              values
              criterion
            }
            datePreset
          }
        }
      }
    }
  }
`;

export const DashboardService = {
  createOrUpdateDashboard: async (config: Partial<DashboardConfigurationJSON>): Promise<Mutation<{ createOrUpdateDashboardConfiguration: number }>> =>
    client.mutate(createDashboardMutation, { config }),

  getDashboard: async (id: number): Promise<Partial<DashboardConfigurationJSON>> => {
    const resp = await client.query(getDashboardConfigurationQuery, { id });
    return cleanDeep(resp.data?.getDashboardConfiguration, { cleanKeys: ["__typename"], nullValues: true, emptyArrays: false, emptyStrings: false });
  },
};
